import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Loading } from 'sw-ui';

import COMPONENTS from '../../../../bi/constants/components';

import { Complaints } from './complaints';
import { Bonuses } from './bonuses';

import styles from '../../styles/statistic.module.css';

const LABELS = {
  TITLES_STATISTIC: 'Название',
  CURRENT_MONTH_STATISTIC: 'Текущий месяц',
  PREVIOUS_MONTH_STATISTIC: 'Предыдущий месяц',
  NO_DATA_STATISTIC: 'Нет данных',
  COMPLAINT_STATISTIC: 'Жалобы в контроль качества',
};

class Statistic extends Component {
  static propTypes = {
    staticsService: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    const { staticsService } = props;
    const {
      statics: {
        isLoading,
        functional,
        bonuses,
      },
      complaints: {
        isLoading: isLoadingComplaints,
        complaintsContent,
      },
    } = staticsService.get();

    this.state = {
      isLoading,
      functional,
      isLoadingComplaints,
      complaintsContent,
      showComplaintsDialog: false,
      bonuses,
    };
  }

  componentDidMount() {
    const { accountId, staticsService } = this.props;

    this.unsubscribe = staticsService.subscribe(this.update.bind(this));
    staticsService.getStatisticFunctional(accountId);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  update = ({
    statics: { isLoading, functional, bonuses },
    complaints: {
      isLoading: isLoadingComplaints,
      complaintsContent,
    },
  }) => this.setState({
    functional,
    isLoading,
    isLoadingComplaints,
    complaintsContent,
    bonuses,
  });

  handleSaveBonuses = async (value) => {
    const { accountId, staticsService } = this.props;

    await staticsService.updateStatisticBonuses(accountId, value);
  }

  renderLoading = () => (
    <div className={ styles.loading }>
      <Loading size='large' />
    </div>
  );

  renderEmpty = () => (
    <div className={ styles.empty }>
      { LABELS.NO_DATA_STATISTIC }
    </div>
  );

  renderFunctionalItem = ({ key, label, currentMonthValue, previousMonthValue }) => (
    <div className={ styles.item } key={ key }>
      <div className={ styles.field_name }>
        {label}
      </div>
      <div className={ styles.current_month }>
        {currentMonthValue}
      </div>
      <div className={ styles.previous_month }>
        {previousMonthValue}
      </div>
    </div>
  );

  renderShowComplaints = () => (
    <div className={ styles.wrapper_complaints }>
      <Button
        className={ styles.show_complaints }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ () => this.setState({ showComplaintsDialog: true }) }
      >
        { LABELS.COMPLAINT_STATISTIC }
      </Button>
    </div>
  );

  renderComplaintsDialog = () => {
    const { accountId, staticsService } = this.props;
    const { complaintsContent, isLoadingComplaints, showComplaintsDialog } = this.state;

    if (!showComplaintsDialog) {
      return null;
    }

    return (
      <Complaints
        accountId={ accountId }
        complaintsContent={ complaintsContent }
        isLoading={ isLoadingComplaints }
        staticsService={ staticsService }
        onClose={ () => this.setState({ showComplaintsDialog: false }) }
      />
    );
  }

  renderContent = () => {
    const { functional, bonuses } = this.state;

    const htmlStatistic = functional.length ? (
      <div>
        <div className={ `${styles.item} ${styles.header}` }>
          <div className={ styles.field_name }>{ LABELS.TITLES_STATISTIC }</div>
          <div className={ styles.item }>{ LABELS.CURRENT_MONTH_STATISTIC }</div>
          <div className={ styles.item }>{ LABELS.PREVIOUS_MONTH_STATISTIC }</div>
        </div>
        {functional.map(this.renderFunctionalItem)}
      </div>
    ) : this.renderEmpty();

    return (
      <div className={ styles.wrap }>
        { htmlStatistic }
        { this.renderShowComplaints() }
        <Bonuses
          comment={ bonuses }
          onSave={ this.handleSaveBonuses }
        />
      </div>
    );
  };

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return this.renderLoading();
    }

    return (
      <div className={ styles.panel } >
        { this.renderContent() }
        { this.renderComplaintsDialog() }
      </div>
    );
  }
}

export { Statistic };
