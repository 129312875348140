import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import { Dialog, Button } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import { setSelectMenuStyle } from '../../../../../bi/utils/account';

import { DECISIONBUTTONS } from '../../../../../bi/constants/decisionButtons';
import { DIALOGSTITLES } from '../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../bi/constants/components';

import styles from '../../../styles/company.module.scss';

const LABELS = {
  COMPANY: 'Компания: (номер или название)',
  OK: 'Oк',
};

const SIZES = {
  TOP: '70px',
  LARGE: '500px',
};

const MoveTrip = ({
  onClose,
  onHandleSelectCompany,
  tripService,
  closingDocuments,
  integration1CMessages,
  errorMessages,
  onHandleMoveTrips,
  waitResMove,
  hasResultMoveTrips,
}) => {
  const [company, setCompany] = useState({ label: '', suggests: [] });
  const [isDisabledMoveButton, setIsDisabledMoveButton] = useState(true);

  const MENU_STYLE = {
    ...setSelectMenuStyle(company.suggests),
    top: SIZES.TOP,
  };

  const LABEL = closingDocuments.length ? DECISIONBUTTONS.LABELS.MOVEANYWAY : DECISIONBUTTONS.LABELS.MOVE;

  const isErrorMessages = errorMessages.length;
  const isClosingDocuments = closingDocuments.length;
  const isIntegration1CMessages = integration1CMessages.length;

  const handleSelectSuggest = (_, { ShortCompanyName, CompanyName, CompanyId }) => {
    setCompany(state => ({ ...state, label: `${ShortCompanyName || CompanyName} (${CompanyId})` }));
    onHandleSelectCompany(CompanyId);
    setIsDisabledMoveButton(false);
  };

  const handleChangeSuggest = async (value) => {
    setIsDisabledMoveButton(true);
    setCompany(state => ({ ...state, label: value }));

    const result = await tripService.autocompleteCompany(value);

    setCompany(state => ({ ...state, suggests: result }));
  };

  const renderItem = (item) => {
    if (!item) {
      return null;
    }

    return (
      <div className={ styles.autocomplete_item } key={ item.CompanyId }>
        { `${item.ShortCompanyName || item.CompanyName} (${item.CompanyId})` }
      </div>
    );
  };

  const renderAutocompleteInput = () => {
    if (isErrorMessages) {
      return null;
    }

    return (
      <div className={ styles.autocomplete_input }>
        <label>{ LABELS.COMPANY }</label>
        <Autocomplete
          menuStyle={ MENU_STYLE }
          value={ company.label }
          items={ company.suggests }
          getItemValue={ ({ ShortCompanyName, CompanyName }) => ShortCompanyName || CompanyName }
          onSelect={ (cValue, value) => handleSelectSuggest(cValue, value) }
          onChange={ ({ target: { value } }) => handleChangeSuggest(value) }
          renderItem={ renderItem }
        />
      </div>
    );
  };

  const renderClosingDocuments = (message) => {
    if (!isClosingDocuments) {
      return null;
    }

    return (
      <div className={ styles.closing_list }>
        { message }
        { closingDocuments.map((item, idx) => <div key={ idx }>{ item }</div>) }
      </div>
    );
  };

  const renderIntegration1CMessages = () => {
    if (!isIntegration1CMessages) {
      return null;
    }

    return (
      <div className={ styles.closing_list }>
        { DIALOGSTITLES.TRIPATTENTION1C }
        { integration1CMessages.map((item, idx) => <div key={ idx }>{ item }</div>) }
      </div>
    );
  };

  const renderWarningInfo = (message) => {
    if (!isClosingDocuments && !isIntegration1CMessages) {
      return null;
    }

    return (
      <div className={ styles.warning_wrap }>
        { renderClosingDocuments(message) }
        { renderIntegration1CMessages() }
      </div>
    );
  };

  const renderError = () => {
    if (!isErrorMessages) {
      return null;
    }

    return (
      <div className={ styles.closing_list }>
        { errorMessages.map((item, idx) => <div key={ idx } className={ styles.errors }>{ item }</div>) }
      </div>
    );
  };

  const renderDecisionButtons = () => {
    if (isErrorMessages) {
      return null;
    }

    return (
      <DecisionButtons
        waitingResponse={ waitResMove }
        disabled={ isDisabledMoveButton }
        onCancel={ onClose }
        onSave={ onHandleMoveTrips }
        labelSave={ LABEL }
      />
    );
  };

  const renderResultContent = (message = '') => (
    <div className={ styles.result_content }>
      { message }
      <div className={ styles.actions_wrap }>
        <Button
          label={ LABELS.OK }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ onClose }
          className={ styles.buttons }
        />
      </div>
    </div>
  );

  const renderResultMoveTrips = () => {
    if (isErrorMessages) {
      return (
        <Fragment>
          { renderError() }
          { renderResultContent() }
        </Fragment>
      );
    }

    if (isClosingDocuments) {
      return (
        <Fragment>
          { renderWarningInfo(DIALOGSTITLES.MOVE_TRIPS_WARNING) }
          { renderResultContent() }
        </Fragment>
      );
    }

    return renderResultContent(DIALOGSTITLES.MOVE_TRIPS);
  };

  const renderMoveTripsDialog = () => {
    if (hasResultMoveTrips) {
      return renderResultMoveTrips();
    }

    return (
      <Fragment>
        { renderWarningInfo(DIALOGSTITLES.MOVE_NOT_POSSIBLE.TRIPS) }
        { renderError() }
        { renderAutocompleteInput() }
        { renderDecisionButtons() }
      </Fragment>
    );
  };

  return (
    <Dialog
      onClick={ onClose }
      width={ SIZES.LARGE }
    >
      <div className={ styles.move_dialog }>
        { renderMoveTripsDialog() }
      </div>
    </Dialog>
  );
};

MoveTrip.propTypes = {
  onClose: PropTypes.func.isRequired,
  onHandleSelectCompany: PropTypes.func.isRequired,
  tripService: PropTypes.object.isRequired,
  closingDocuments: PropTypes.array.isRequired,
  integration1CMessages: PropTypes.array.isRequired,
  errorMessages: PropTypes.array.isRequired,
  onHandleMoveTrips: PropTypes.func.isRequired,
  waitResMove: PropTypes.bool.isRequired,
  hasResultMoveTrips: PropTypes.bool.isRequired,
};

export { MoveTrip };
