import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dialog, Button, Loading } from 'sw-ui';

import { Complaint } from './complaint';

import COMPONENTS from '../../../../../bi/constants/components';

import styles from '../../../styles/complaints.module.css';

const { BUTTON: { THEME: { RED } } } = COMPONENTS;

const WIDTH = '1200px';

const LABELS = {
  TITLE_COMPLAINTS: 'Жалобы в контроль качества',
  ADD_COMPLAINT: 'Добавить',
  DATE_COMPLAINT: 'Дата',
  LINK_COMPLAINT: 'Ссылка',
  COMMENT_COMPLAINT: 'Комментарий',
  NO_COMPLAINTS: 'Жалоб нет',
  CLOSE: 'Закрыть',
};

class Complaints extends Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    complaintsContent: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    staticsService: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isEditingAll: false,
      isEditingCurrenItem: '',
      isRemovingCurrenItem: '',
    };
  }

  componentDidMount() {
    const { accountId, staticsService } = this.props;

    staticsService.getStatisticComplaints(accountId);
  }

  handleChangeComplaint = (complaint) => {
    this.setState({
      isEditingAll: true,
      isEditingCurrenItem: complaint.id,
    });
  }

  handleChangeComplaintSave = async (complaint) => {
    const { staticsService, accountId } = this.props;

    if (complaint.id) {
      await staticsService.saveUpdateStatisticComplaint({
        account_id: accountId,
        ...complaint,
      });
    } else {
      await staticsService.saveCreateStatisticComplaint({
        account_id: accountId,
        ...complaint,
      });
    }

    this.setState({
      isEditingAll: false,
      isEditingCurrenItem: '',
    });
  }

  handleChangeComplaintCancel = () => this.setState({
    isEditingCurrenItem: '',
    isEditingAll: false,
  });

  handleRemoveComplaint = async (complaint) => {
    const { staticsService } = this.props;

    this.setState({
      isEditingAll: true,
      isRemovingCurrenItem: complaint.id,
    });

    if (complaint.id) {
      await staticsService.saveRemoveStatisticComplaint(complaint.id);
    } else {
      staticsService.removeStatisticComplaint(complaint.id);
    }

    this.setState({
      isEditingAll: false,
      isRemovingCurrenItem: '',
    });
  }

  handleAddComplaint = () => {
    const { staticsService } = this.props;

    staticsService.createStatisticComplaint();

    this.setState({ isEditingAll: true });
  }

  renderLoading = () => (
    <div className={ styles.loading }>
      <Loading size='large' />
    </div>
  );

  renderComplaints = () => {
    const { isLoading, complaintsContent } = this.props;
    const { isEditingAll, isEditingCurrenItem, isRemovingCurrenItem } = this.state;

    if (isLoading && !isEditingAll) {
      return this.renderLoading();
    }

    if (!complaintsContent.length) {
      return (
        <div className={ `${styles.header}` }>{ LABELS.NO_COMPLAINTS }</div>
      );
    }

    return complaintsContent.map(complaint => (
      <Complaint
        key={ `complaint_${complaint.id}` }
        isEditingAll={ isEditingAll }
        isRemovingCurrenItem={ isRemovingCurrenItem }
        isEditingCurrenItem={ isEditingCurrenItem }
        isLoading={ isLoading }
        complaint={ complaint }
        onChangeComplaint={ changeComplaint => this.handleChangeComplaint(changeComplaint) }
        onChangeComplaintSave={ changeComplaint => this.handleChangeComplaintSave(changeComplaint) }
        onChangeComplaintCancel={ changeComplaint => this.handleChangeComplaintCancel(changeComplaint) }
        onRemoveComplaint={ changeComplaint => this.handleRemoveComplaint(changeComplaint) }
      />
    ));
  }

  renderContent = () => {
    const { isEditingAll } = this.state;

    return (
      <section className={ styles.wrap }>
        <h3 className={ styles.header }>{ LABELS.TITLE_COMPLAINTS }</h3>
        <div className={ styles.buttons }>
          <Button
            className={ styles.button_add_complaint }
            label={ LABELS.ADD_COMPLAINT }
            theme={ RED }
            onClick={ this.handleAddComplaint }
            disabled={ isEditingAll }
          />
        </div>
        <div className={ `${styles.row} ${styles.header}` }>
          <div className={ styles.item }>{ LABELS.DATE_COMPLAINT }</div>
          <div className={ styles.item }>{ LABELS.LINK_COMPLAINT }</div>
          <div className={ styles.item }>{ LABELS.COMMENT_COMPLAINT }</div>
        </div>
        <div className={ styles.wrap_content }>
          { this.renderComplaints() }
        </div>
      </section>
    );
  }

  render() {
    const { onClose } = this.props;

    return (
      <Dialog
        width={ WIDTH }
        onClick={ onClose }
        showCloseButton
      >
        { this.renderContent() }
      </Dialog>
    );
  }
}

export { Complaints };
